#main-nav {
	position: fixed;
	top: 64px;
	@include vw(left, 156px, 32px);
	display: flex;
	z-index: 10;
	@include text-m-uppercase-medium;

	@include bp-767 {
		top: 32px;
	}
}

.nav-el {
	transform: translateY(7vh);
	opacity: 0;
	transition: transform 1s var(--ease-in-out-quad), opacity 0.8s linear;

	&:nth-child(2) {
		transition-delay: 0.04s;
	}

	a,
	button {
		display: inline-block;
		position: relative;
		white-space: nowrap;
		position: relative;
		color: var(--text-color);

		&:before {
			content: "";
			position: absolute;
			top: -4px;
			left: -4px;
			width: calc(100% + 8px);
			height: calc(100% + 8px);
		}

		@include hover {
			svg {
				transform: translate(0, -50%) rotate(0);
				opacity: 1;
			}
		}

		&.is-active {
			svg {
				transform: translate(0, -50%) rotate(0);
				opacity: 1;
			}
		}
	}

	svg {
		position: absolute;
		top: 45%;
		left: -17px;
		width: 8px;
		height: 8px;
		margin-right: 7px;
		fill: var(--text-color);

		transform: translate(-1.5vw, -50%) rotate(-235deg);
		opacity: 0;
		transition: transform 0.35s ease-in-out, opacity 0.35s linear;
	}

	&:first-child,
	&:nth-child(2) {
		width: 11.25vw;

		@include bp-767 {
			width: 22.5vw;
		}
	}
}

body.is-loaded {
	.nav-el {
		transform: translateY(0);
		opacity: 1;
	}
}
