@import "../scss_critical/mixins.scss";
@import "./typography.scss";
@import "./components/media.scss";
@import "./components/footer.scss";
@import "./components/main-nav.scss";
@import "./components/transition-loader.scss";
@import "./components/slider.scss";

@import "./pages/home.scss";
@import "./pages/about.scss";
@import "./pages/impressum.scss";

body {
	&.project-open {
		cursor: pointer;

		.project {
			transform: translateX(-40%);
		}

		.project-info {
			cursor: auto;
		}
	}

	&.is-loaded {
		transition: background 0.4s linear;

		#loader {
			pointer-events: none;

			&:before {
				transition: transform 0.6s var(--ease-in-quart);
				transform: translateY(100%);
			}

			&:after {
				transition: transform 0.6s var(--ease-in-quart), opacity 0.4s linear;
				transform: translateY(100%);
				opacity: 0;
			}
		}
	}
}

.project {
	transition: transform 1.2s var(--ease-in-out-quart) 0.15s;
}

main {
	padding-top: 25vh;
	overflow: hidden;
	width: 100%;
	transform: translateY(25vh);

	@include bp-767 {
		padding-top: 140px;
	}
}

body.is-loaded {
	main {
		transition: transform 0.8s var(--ease-in-out-quad) 0.15s;
		transform: initial;
	}
}

h1,
h2,
h3 {
	@include headline-l-uppercase;
}

button {
	@include text-m-uppercase-medium;
}

.desktop-only {
	@include bp-767 {
		display: none !important;
	}
}
