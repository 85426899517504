@import "../scss_critical/mixins.scss";
@import "../scss_critical/variables.scss";

@mixin headline-l($lineHeight: 1, $textTransform: initial) {
	text-transform: $textTransform;
	line-height: $lineHeight;
	font-family: GT Medium;
	@include vw(font-size, 52px, 30px, 52px);
}

@mixin headline-l-uppercase($lineHeight: 1) {
	@include headline-l($lineHeight, uppercase);
}

@mixin text-m($lineHeight: 1, $textTransform: initial, $fontWeight: regular) {
	// @include vw(font-size, 12px, 11px, 12px);
	font-size: 12px;
	text-transform: $textTransform;
	line-height: $lineHeight;

	@if $fontWeight == medium {
    font-family: GT Medium;
  }
  @else  {
    font-family: GT Regular;
  }

	@include bp-767 {
		font-size: 11px;
	}
}


@mixin text-m-uppercase-medium($lineHeight: 1) {
	@include text-m($lineHeight, uppercase, medium);
}
