@import "../../scss_critical/mixins.scss";

#project-number-wrapper {
	position: fixed;
	top: 50%;
	left: 40px;
	letter-spacing: 0.05em;
	font-feature-settings: "tnum";
	z-index: 9;
	color: var(--text-color);
	@include text-m(1, "initial", "medium");

	opacity: 0;
	transition: opacity 0.3s linear 0.8s;

	p {
		transform: rotate(-90deg) translateY(-50%);
	}

	@include bp-767 {
		top: auto;
		left: auto;
		bottom: 24px;
		right: 5.6vw;

		p {
			transform: initial;
		}
	}
}

body {
	&.is-loaded {
		#project-number-wrapper {
			opacity: 1;
		}
	}
}

.project {
	position: relative;
	width: 100%;
	@include vw(padding-left, 157px, 32px);
	@include vw(padding-right, 157px, 32px);
	margin-bottom: 45vh;
	color: var(--text-color);

	@include bp-767 {
		margin-bottom: 25vh;
	}

	.text-wrapper {
		z-index: 1;
		position: absolute;
		left: 20.56%;
		top: 5vh;

		@include bp-767 {
			position: relative;
			margin-left: 0;
			top: auto;
			left: auto;
			margin-bottom: 40px;
		}
	}

	button {
		@include vw(margin-top, 12px, 8px);
		display: flex;
		align-items: center;
		margin-left: -2.2%;
		color: var(--text-color);

		svg {
			@include vw(width, 10px, 8px, 10px);
			@include vw(margin-right, 10px, 8px);
			@include vw(margin-top, 3px, 1px);
			fill: var(--text-color);
		}

		@include bp-767 {
			margin-left: 0;
		}
	}

	.media-wrapper {
		width: 100%;
		display: flex;
		justify-content: space-between;
		@include bp-767 {
			flex-direction: column-reverse;
		}

		.left {
			width: 32%;
			@include vw(margin-top, 160px, 120px);

			@include bp-767 {
				margin-top: 32px;

				.media {
					&:nth-child(even) {
						margin-left: 15vw;
					}
				}
			}
		}

		.right {
			width: 58%;

			.full-width {
				width: 100%;
			}

			.small {
				width: 55.5%;
				margin: 0 auto;

				@include bp-767 {
					width: 100%;
				}
			}
		}

		.left,
		.right {
			display: flex;
			flex-direction: column;
			@include vw(gap, 40px, 32px);

			@include bp-767 {
				width: calc(100% - 15vw);
				display: grid;
				grid-auto-flow: row;
			}
		}
	}

	.right {
		.full-width,
		.small {
			@include bp-767 {
				&:nth-child(even) {
					margin-left: 15vw;
				}
			}
		}
	}
}

.project-info {
	position: fixed;
	top: 0;
	right: 0;
	width: 72vw;
	height: 100%;
	overflow-y: scroll;
	overflow-x: hidden;
	z-index: 11;

	transform: translateX(102%);
	transition: transform 1.2s var(--ease-in-out-quart);
	background-color: var(--black-alpha);
	color: var(--white-blue);
	@include vw(padding-top, 72px, 40px);
	@include vw(padding-bottom, 22px, 22px);
	@include vw(padding-left, 40px, 40px);
	@include vw(padding-right, 40px, 40px);

	@include bp-767 {
		background-color: #2b2b2c;
	}

	&.is-open {
		transform: translateX(0);
	}

	p {
		@include text-m(1.6);
		font-size: 14px;
	}

	button {
		position: absolute;
		@include vw(right, 40px, 40px);
		@include vw(top, 72px, 40px);
		color: var(--white-blue);
		display: flex;
		align-items: center;

		svg {
			@include vw(width, 13px, 10px, 13px);
			@include vw(margin-right, 6px, 2px);
			@include vw(margin-top, 2px, 2px);
		}

		@include bp-767 {
			position: relative;
			right: auto;
			top: auto;
			margin-bottom: 40px;
		}
	}

	@include bp-767 {
		padding-bottom: 40px;
		padding-right: 16px;
		padding-left: 16px;
		width: 100%;
	}
}

.project-info-head {
	width: 60%;

	h3 {
		@include vw(margin-bottom, 40px, 24px);
	}

	@include bp-767 {
		width: 75vw;
	}
}

.project-info-media-wrapper {
	display: grid;
	@include vw(gap, 24px, 20px);
	@include vw(margin-top, 120px, 80px);

	p {
		@include text-m-uppercase-medium;
	}

	.horizontal {
		width: 80%;

		@include bp-767 {
			width: 100%;
		}
	}

	.vertical {
		width: 50%;

		@include bp-767 {
			width: 100%;
		}
	}
}
