.impressum-text {
	width: 30vw;
	margin-left: 13vw;
	@include vw(margin-bottom, 80px, 80px);
	@include text-m(1.4, "initial", "medium");

	strong {
		display: inline-block;
		@include vw(margin-top, 24px, 12px);
		@include vw(margin-bottom, 12px, 12px);
	}

	@include bp-767 {
		width: 67.5vw;
	}
}
