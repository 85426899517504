.media {
	position: relative;
	width: 100%;
	padding-top: calc(100% * var(--ar));
	overflow: hidden;

	video, img {
		position: absolute;
		top: 0;
		left: 0;
	}
}
