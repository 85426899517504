footer {
	@include vw(margin-bottom, 80px, 24px);
	@include vw(margin-left, 156px, 32px);
	@include text-m-uppercase-medium;
	display: flex;

	@include bp-767 {
		margin-left: 5vw;
	}
}

.footer-el {
	button {
		color: var(--black);
	}

	&:first-child {
		width: 11.25vw;

		@include bp-767 {
			margin-left: 0;
			width: 15vw;
		}
	}

	&:nth-child(2) {
		width: 15vw;
	}
}
