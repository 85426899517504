.slider {
	overflow: hidden;
	cursor: grab;

	&:active {
		cursor: grabbing;
	}
}

.slides-wrapper {
	display: flex;
	@include vw(gap, 32px, 12px);
}

.slider-header {
	display: flex;
	@include vw(margin-bottom, 36px, 24px);

	p {
		@include text-m(1, uppercase, medium);
	}

	.slider-arrows {
		display: flex;
		@include vw(gap, 16px, 12px);
		@include vw(margin-left, 350px, 120px);
	}

	button {
		color: var(--black);

		svg {
			@include vw(width, 10px, 8px, 10px);
			@include vw(margin-left, 8px, 6px);
			@include vw(margin-right, 8px, 6px);
		}

		&.prev {
			svg {
				transform: rotate(-180deg) translateY(18%);
			}
		}
	}
}
