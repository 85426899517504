#transition-loader {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 9;
	background-color: var(--white);

	opacity: 0;
	transition: opacity 0.3s linear;
	pointer-events: none;

	&.is-visible {
		pointer-events: auto;
		opacity: 1;
	}
}
