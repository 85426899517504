@import "../../scss_critical/mixins.scss";

.about-header {
	@include vw(padding-left, 157px, 32px);
	@include vw(padding-right, 157px, 32px);

	display: flex;

	.image-wrapper {
		width: 50%;
	}

	.text-wrapper {
		width: 28%;
		@include vw(padding-top, 160px, 120px);
		margin-left: 10%;

		h1 {
			@include vw(margin-bottom, 40px, 32px);
		}

		p,
		a {
			@include text-m(1.4, initial, medium);
		}
	}

	.social-links {
		@include vw(margin-top, 56px, 40px);
		line-height: 1.4;

		li {
			position: relative;
		}

		.image-wrapper {
			display: grid;
			place-items: center;
			position: absolute;
			top: 3px;
			left: -16px;
			width: 11px;
			height: 11px;
		}
	}

	@include bp-767 {
		display: block;

		.image-wrapper {
			width: 100%;
		}

		.text-wrapper {
			width: 100%;
			padding-top: 56px;
			padding-left: 3.75%;
			margin-left: 0;
			max-width: 480px;

			h1 {
				margin-bottom: 16px;
			}
		}
	}
}

.about-links {
	@include vw(margin-top, 184px, 160px);
	@include vw(margin-bottom, 240px, 80px);

	.slider-header {
		@include vw(padding-left, 156px, 32px);
	}

	.number {
		width: 7.5vw;
	}

	.slide {
		display: block;
		@include text-m(1, initial, medium);

		svg {
			@include vw(width, 28px, 26px, 28px);
			transform: rotate(-45deg);
			opacity: 0;
			transition: opacity 0.3s linear;
		}

		h3 {
			@include vw(margin-top, 12px, 6px);
			@include vw(margin-bottom, 12px, 6px);
		}

		@include hover {
			svg {
				opacity: 1;
			}
		}

		&:first-child {
			margin-left: 16.9vw;
		}
	}
}
